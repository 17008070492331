import firebase from "firebase/app";
import { initializeApp } from "firebase/app";
import { getAuth } from "@firebase/auth";
import { getStorage, ref } from "@firebase/storage";
import { getDatabase } from "@firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyCkEVr0UicwZsSuyMtIh1Z8syzETyOOhDM",
  authDomain: "investa-insights.firebaseapp.com",
  projectId: "investa-insights",
  storageBucket: "investa-insights.appspot.com",
  messagingSenderId: "298394042415",
  appId: "1:298394042415:web:eea9e55c64d38d958346da",
};

export default initializeApp(firebaseConfig);
export const auth = getAuth();
export const storage = getStorage();
export const database = getDatabase();
